import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('loads', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`loads/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('loads', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.put(`loads/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`loads/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        
        suppliers: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('suppliers', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        locations: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('locations', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        warehouses: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('warehouses', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        transporters: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('transporters', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        inputs: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('inputs', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        drivers: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.get('transporter_drivers', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fleets: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('transporter_fleets', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
