<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="md" @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="transport.id === null">
                    <span v-if="transaction === 0">Receive Supplier Delivery</span>
                    <span v-else-if="transaction === 1">Inputs Dispatching</span>
                    <span v-else>Inputs Receiving</span>
                </h5>
                <h5 v-else>
                    <span v-if="transaction === 0">Edit Supplier Delivery</span>
                    <span v-else-if="transaction === 1">Edit Dispatched Inputs</span>
                    <span v-else>Edit Received Inputs</span>
                </h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="sm" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group label-for="source_id" :label="transaction === 0? 'From Supplier': 'Source Store'">
                                    <validation-provider #default="{ errors }" name="source_id" rules="">
                                        <b-form-select
                                            id="source_id"
                                            name="source_id"
                                            size="sm"
                                            v-model="transport.source_id"
                                        >
                                            <b-form-select-option :value="null">Select --</b-form-select-option>
                                            <b-form-select-option v-for="source in sources" :key="source.id" :value="source.id">
                                                {{ source.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.source_id">{{ serverErrors.source_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="6">
                                <b-form-group label-for="destination_id" :label="'Receiving Store'">
                                    <validation-provider #default="{ errors }" name="destination_id" rules="">
                                        <b-form-select
                                            id="destination_id"
                                            name="destination_id"
                                            size="sm"
                                            v-model="transport.destination_id"
                                        >
                                            <b-form-select-option :value="null">Select --</b-form-select-option>
                                            <b-form-select-option v-for="destination in destinations" :key="destination.id" :value="destination.id">
                                                {{ destination.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.destination_id">{{ serverErrors.destination_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="12">
                                <b-form-group label-for="transporter_id" label="Transporter">
                                    <validation-provider #default="{ errors }" name="transporter_id" rules="">
                                        <b-form-select
                                            id="transporter_id"
                                            name="transporter_id"
                                            size="sm"
                                            v-model="transport.transporter_id"
                                            @change="changeTransporter()"
                                        >
                                            <b-form-select-option :value="null">Select--</b-form-select-option>
                                            <b-form-select-option v-for="transporter in transporters" :key="transporter.id" :value="transporter.id">
                                                <span v-if="transporter.code !== null">{{ transporter.code.toUpperCase() }}</span> <span>{{ transporter.name.toUpperCase() }}</span>
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.transporter_id">{{ serverErrors.transporter_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group label-for="driver_id" label="Driver">
                                    <validation-provider #default="{ errors }" name="driver_id" rules="">
                                        <b-form-select
                                            id="driver_id"
                                            name="driver_id"
                                            size="sm"
                                            v-model="transport.driver_id"
                                        >
                                            <b-form-select-option :value="null">Select--</b-form-select-option>
                                            <b-form-select-option v-for="driver in drivers" :key="driver.id" :value="driver.id">
                                                <span v-if="driver.person.middleName !== null">
                                                    {{ driver.person.firstName.toUpperCase() }} {{ driver.person.middleName.toUpperCase() }} {{ driver.person.lastName.toUpperCase() }}
                                                </span>
                                                <span v-else>{{ driver.person.firstName.toUpperCase() }} {{ driver.person.lastName.toUpperCase() }}</span>
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.driver_id">{{ serverErrors.driver_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="6">
                                <b-form-group label-for="license" label="Driving License">
                                    <validation-provider #default="{ errors }" name="license" rules="">
                                        <b-form-select
                                            id="license"
                                            name="license"
                                            size="sm"
                                            v-model="transport.driver_id"
                                        >
                                            <b-form-select-option :value="null">Select --</b-form-select-option>
                                            <b-form-select-option v-for="driver in drivers" :key="driver.id" :value="driver.id">
                                                {{ driver.license.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.license">{{ serverErrors.license[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group label-for="truck_id" label="Truck">
                                    <validation-provider #default="{ errors }" name="truck_id" rules="">
                                        <b-form-select
                                            id="truck_id"
                                            name="truck_id"
                                            size="sm"
                                            v-model="transport.truck_id"
                                        >
                                            <b-form-select-option :value="null">Select--</b-form-select-option>
                                            <b-form-select-option v-for="fleet in fleets.filter((e) => !e.isTrailer)" :key="fleet.id" :value="fleet.id">
                                                {{ fleet.plateNumber.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.truck_id">{{ serverErrors.truck_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="6">
                                <b-form-group label-for="trailer_id" label="Trailer">
                                    <validation-provider #default="{ errors }" name="trailer_id" rules="">
                                        <b-form-select
                                            id="trailer_id"
                                            name="trailer_id"
                                            size="sm"
                                            v-model="transport.trailer_id"
                                        >
                                            <b-form-select-option :value="null">Select --</b-form-select-option>
                                            <b-form-select-option v-for="fleet in fleets.filter((e) => e.isTrailer)" :key="fleet.id" :value="fleet.id">
                                                {{ fleet.plateNumber.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.trailer_id">{{ serverErrors.trailer_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group label-for="number" label="Serial #">
                                    <validation-provider #default="{ errors }" name="number" rules="">
                                        <b-form-input
                                            id="number"
                                            name="number"
                                            size="sm"
                                            v-model="transport.number"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.number">{{ serverErrors.number[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="6" v-if="transaction === 0">
                                <b-form-group label-for="orderNumber" label="Reference Number">
                                    <validation-provider #default="{ errors }" name="orderNumber" rules="">
                                        <b-form-input
                                            id="orderNumber"
                                            name="orderNumber"
                                            size="sm"
                                            v-model="transport.orderNumber"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.orderNumber">{{ serverErrors.orderNumber[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="6" v-if="transaction !== 0">
                                <b-form-group label-for="transactionDate" label="Transaction Date">
                                    <validation-provider #default="{ errors }" name="transactionDate" rules="">
                                        <b-form-datepicker
                                            id="transactionDate"
                                            name="transactionDate"
                                            size="sm"
                                            v-model="transport.transactionDate"
                                            :state="errors.length > 0 ? false : null"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en" :max="new Date().toISOString().split('T')[0]"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.transactionDate">{{ serverErrors.transactionDate[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="transaction === 0">
                            <b-col cols="12" md="6">
                                <b-form-group label-for="transactionDate" label="Receiving Date">
                                    <validation-provider #default="{ errors }" name="transactionDate" rules="">
                                        <b-form-datepicker
                                            id="transactionDate"
                                            name="transactionDate"
                                            size="sm"
                                            v-model="transport.transactionDate"
                                            :state="errors.length > 0 ? false : null"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en" :max="new Date().toISOString().split('T')[0]"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.transactionDate">{{ serverErrors.transactionDate[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- Inputs List -->
                        <b-row>
                            <b-col cols="12">
                                <b-table
                                    :items="transport.inputs"
                                    :fields="inputsColumns"
                                    small
                                    responsive="sm"
                                    sticky-header="240px"
                                >
                                    <template #cell(quantity)="data">
                                        <validation-provider #default="{ errors }" :name="'quantity_' + data.index" rules="">
                                            <b-form-input
                                                :id="'quantity_' + data.index"
                                                :name="'quantity_' + data.index"
                                                size="sm"
                                                v-model="transport.inputs[data.index].quantity"
                                                :state="errors.length > 0 ? false : null"
                                                type="number"
                                                style="text-align:right;"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.transporter_id">{{ serverErrors.transporter_id[0] }}</small>
                                        </validation-provider>
                                    </template>

                                    <template #cell(mass)="data">
                                        <validation-provider #default="{ errors }" :name="'mass_' + data.index" rules="">
                                            <b-form-input
                                                :id="'mass_' + data.index"
                                                :name="'mass_' + data.index"
                                                size="sm"
                                                v-model="transport.inputs[data.index].mass"
                                                :state="errors.length > 0 ? false : null"
                                                type="number"
                                                style="text-align:right;"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.transporter_id">{{ serverErrors.transporter_id[0] }}</small>
                                        </validation-provider>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- <b-row>
                            <b-col cols="12">
                                {{ transport }}
                            </b-col>
                        </b-row> -->
                        <!-- ./Inputs List -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal To add Fleet -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm"
                        />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                size="sm"
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                            />
                            <b-button
                                v-if="transaction !== 2"
                                variant="primary"
                                size="sm"
                                @click="invokeCreateForm()"
                            >
                                <span class="text-nowrap" v-if="transaction === 0">New Supplier Delivery</span>
                                <span class="text-nowrap" v-else>New Dispatch</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :fields="columns"
                :items="fetch"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >

                <template #cell(company)="data">
                    <span v-if="data.item.company">{{ data.item.company.name.toUpperCase() }}</span>
                </template>


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, onUpdated } from '@vue/composition-api'
import transportsStoreModule from '@/views/cromis/transportations/transportsStoreModule'
import useTransportsList from '@/views/cromis/transportations/useTransportsList'

export default {
    props: {
        transaction: {
            type: Number,
        },
    },
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, BFormDatepicker,
    },
    directives: {},
    setup(props, context) {
        const transactionNumber = ref(-1)

        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)

        const sources = ref([])
        const destinations = ref([])

        const transporters = ref([])
        const drivers = ref([])
        const fleets = ref([])

        const inputs = ref([])
        const inputsColumns = ref([
            { key: 'name', label: 'Item', sortable: false, thStyle: { width: "50%" }},
            { key: 'quantity', label: 'Qty', sortable: false , thStyle: { width: "20%" }, thClass: 'text-right', tdClass: 'text-right'},
            { key: 'mass', label: 'KGs', sortable: false, thClass: 'text-right', tdClass: 'text-right' },
        ])

        const transport = ref({
            id: null,
            source_type: props.transaction,
            source_id: null,
            destination_id: null,
            number: null,
            orderNumber: null,
            transporter_id: null,
            driver_id: null,
            truck_id: null,
            trailer_id: null,
            transactionDate: null,
            inputs: inputs.value,
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-transport'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, transportsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            
        })

        onUpdated(async () => {
            if(props.transaction != transactionNumber.value){
                transactionNumber.value = props.transaction
                refetch()

                if(inputs.value.length === 0){
                    await store.dispatch('cromis-transport/inputs')
                            .then(response => {
                                inputs.value = response.data.inputs.map(function(e, index){
                                    return {
                                        id: e.id,
                                        name: e.name,
                                        quantity: null,
                                        mass: null,
                                    };
                                })

                                inputs.value.sort((a, b) => a.name - b.name)
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
                }

                if(transporters.value.length === 0){
                    await store.dispatch('cromis-transport/transporters')
                            .then(response => {
                                transporters.value = response.data.transporters
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
                }

                if(props.transaction === 0){
                    await store.dispatch('cromis-transport/suppliers')
                            .then(response => {
                                sources.value = response.data.suppliers
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })

                    await store.dispatch('cromis-transport/warehouses', { main_store: 1 })
                        .then(response => {
                            destinations.value = response.data.warehouses
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })
                }

                // Dispatch inputs between our own stores
                if(props.transaction === 1){
                    await store.dispatch('cromis-transport/warehouses', { main_store: 1 })
                        .then(response => {
                            sources.value = response.data.warehouses
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })

                    await store.dispatch('cromis-transport/warehouses', { main_store: 0 })
                        .then(response => {
                            destinations.value = response.data.warehouses
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })
                }

                // Tobacco from various villages/locations
                if(props.transaction === 2){
                    await store.dispatch('cromis-transport/locations')
                        .then(response => {
                            sources.value = response.data.locaations
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })

                    await store.dispatch('cromis-transport/warehouses', { main_store: 0 })
                        .then(response => {
                            destinations.value = response.data.warehouses
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })
                }
            }
        })

        const changeTransporter = async () => {
            await store.dispatch('cromis-transport/drivers', { transporter_id: transport.value.transporter_id })
                    .then(response => {
                        drivers.value = response.data.drivers
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })

            await store.dispatch('cromis-transport/fleets', { transporter_id: transport.value.transporter_id })
                    .then(response => {
                        fleets.value = response.data.fleets
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
        } = useTransportsList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null

            transport.value = {
                id: item.id,
                source_type: props.transaction,
                source_id: item.route.source_id,
                destination_id: item.route.destination_id,
                number: item.number,
                orderNumber: item.orderNumber,
                transporter_id: item.transporter_id,
                driver_id: item.driver_id,
                truck_id: item.truck_id,
                trailer_id: item.trailer_id,
                transactionDate: item.transactionDate,
                inputs: item.inputs,
            };

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            transport.value = {
                id: null,
                source_type: props.transaction,
                source_id: null,
                destination_id: null,
                number: null,
                orderNumber: null,
                transporter_id: null,
                driver_id: null,
                truck_id: null,
                trailer_id: null,
                transactionDate: null,
                inputs: inputs.value,
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null

            if (transport.value.id === null || transport.value.id === 0)
                handleCreate()
            else
                handleUpdate(transport.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-transport/create', transport.value)
                .then(response => {
                    refetch()
                    saving.value = false
                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `The transportion has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 2000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-transport/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to transportion ${response.data.number} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 2000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your Transportion is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }

        const remove = async (id) => {
            await store.dispatch('cromis-transport/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            transport,
            sources,
            destinations,
            transporters,
            drivers,
            fleets,
            inputs,
            inputsColumns,

            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            confirmDelete,
            changeTransporter,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
